import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.headers.common["X-Client-Key"] =
  process.env.REACT_APP_TRUSTED_CLIENT_KEY;

const element = document.getElementById("root");
if (!element) {
  throw new Error("Root element not found");
}

ReactDOMClient.createRoot(element).render(<App />);

reportWebVitals();
