export enum COLOR {
  WHITE,
  BLACK,
  GREY,
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  BLUE,
  INDIGO,
  PINK,
}
