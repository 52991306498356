import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import AlertContext from "context/alert.context";
import axios from "axios";
import IsAuthenticatedContext from "context/isauthenticated.context";
import { Button, TextField } from "@mui/material";

export default function Login() {
  const [, setIsAuthenticated] = useContext(IsAuthenticatedContext);
  const [, setMessage, , setSeverity, , setOpen] = useContext(AlertContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const login = () => {
    if (!email) {
      setMessage("Email empty");
      setSeverity("warning");
      setOpen(true);
      return;
    }
    if (!password) {
      setMessage("Password empty");
      setSeverity("warning");
      setOpen(true);
      return;
    }
    if (!validateEmail(email)) {
      setMessage("Email invalid");
      setSeverity("warning");
      setOpen(true);
      return;
    }
    axios
      .post("/api/auth/login", { email, password })
      .then((res) => {
        setMessage("Connected");
        setSeverity("success");
        setOpen(true);
        localStorage.setItem("token", res.data.token);
        setIsAuthenticated(true);
        navigate("/upload");
      })
      .catch((e) => {
        setMessage(e?.response?.data?.message);
        setSeverity("error");
        setOpen(true);
      });
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="login-cont">
      <label
        style={{
          fontSize: "2rem",
        }}
      >
        Login
      </label>
      <TextField
        label="Email"
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        type="text"
        style={{
          margin: 10,
        }}
      />
      <TextField
        label="Password"
        variant="outlined"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        style={{
          margin: 10,
        }}
      />
      <div>
        <Button onClick={login} variant="outlined" className="upload-btn">
          Login
        </Button>
      </div>
    </div>
  );
}
