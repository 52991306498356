import _ from "lodash";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import Delta from "./components/delta.js";
import ImageList from "components/image-list";
import IsAuthenticatedContext from "context/isauthenticated.context";
import Login from "components/login";
import moment from "moment";
import NotFound from "./components/notfound";
import Summary from "./components/summary";
import Title from "./components/title";
import UploadForm from "components/upload-form";
import Polygone from "components/polygone.js";

export default function Router() {
  const [isAuthenticated] = useContext(IsAuthenticatedContext);

  function AuthLayout(): JSX.Element {
    if (isAuthenticated) {
      return <Outlet />;
    }
    return <Navigate to="/" replace />;
  }

  function UnauthLayout(): JSX.Element {
    if (!isAuthenticated) {
      return <Outlet />;
    }
    return <Navigate to="/" replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<Title />} />
      <Route
        path="/polygone"
        element={
          <Summary
            isUnderConstruction
            nodes={[
              { text: "Motion", link: "/motion" },
              { text: "Matter", link: "/matter" },
              { text: "Still", link: "/still" },
              { text: "Thoughts", link: "/thoughts" },
              { text: "Virtual", link: "/virtual" },
              { text: "Term", link: "/term" },
            ]}
          />
        }
      />
      <Route
        path="/motion"
        element={
          <Polygone
            nodes={[
              { text: "Film", link: "/film" },
              { text: "Diary", link: "/diary" },
              { text: "Performance", link: "/performance" },
            ]}
          />
        }
      />
      <Route
        path="/matter"
        element={
          <Polygone
            nodes={[
              { text: "Sculpture", link: "/sculpture" },
              { text: "Installation", link: "/installation" },
            ]}
          />
        }
      />
      <Route
        path="/still"
        element={
          <Polygone
            nodes={[
              { text: "Photography", link: "/photography" },
              { text: "Collage", link: "/collage" },
              { text: "Motif", link: "/motif" },
            ]}
          />
        }
      />
      <Route
        path="/thoughts"
        element={
          <Polygone
            nodes={[
              { text: "Texts", link: "/texts" },
              { text: "Notes", link: "/notes" },
              { text: "Youtube", link: "/youtube" },
            ]}
          />
        }
      />
      <Route
        path="/virtual"
        element={
          <Polygone
            nodes={[
              { text: "website", link: "/website" },
              { text: "Instagram", link: "/instagram" },
              { text: "Vimeo", link: "/vimeo" },
            ]}
          />
        }
      />
      <Route
        path="/term"
        element={
          <Polygone
            nodes={[
              { text: "Bio", link: "/bio" },
              { text: "Notice", link: "/notice" },
              { text: "Contact", link: "/contact" },
            ]}
          />
        }
      />
      <Route
        path="/photography"
        element={
          <Summary
            isUnderConstruction={false}
            nodes={_.range(2005, +moment().format("YYYY")).map((e: number) => {
              return {
                text: e.toString(),
                link: `/photography/${e.toString()}`,
              };
            })}
          />
        }
      />
      <Route
        path="/photography/:year"
        element={
          <Summary
            isUnderConstruction={false}
            nodes={[
              {
                text: "Spring",
                link: "spring",
              },
              {
                text: "Summer",
                link: "summer",
              },
              {
                text: "Fall",
                link: "fall",
              },
            ]}
          />
        }
      />
      <Route path="/photography/:year/:season" element={<Delta />} />
      <Route element={<AuthLayout />}>
        <Route path="/upload" element={<UploadForm />} />
        <Route path="/images" element={<ImageList />} />
      </Route>
      <Route element={<UnauthLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
