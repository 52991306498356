import { isEmpty, range, groupBy } from "lodash";
import { SEASON } from "../enums/season.enum";
import { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import moment from "moment";

function Delta() {
  let { year } = useParams();
  let { season } = useParams();

  let [urlImage, setUrlImage] = useState("");
  let [data, setData] = useState({});
  let [categories, setCategories] = useState([]);
  let [open, setOpen] = useState(false);

  let canvas = useRef(null);
  let lair = useRef(null);

  const xMax = 2.2;
  const xMin = -2.2;
  const yMax = 6;
  const yMin = -6;

  var Maps = () => {
    var bBarnesHutTree = [];
    var iParticleSystem = [100, 60, 60, 60];
    const fixedHeight = 100;
    const fixedWidth = 50;
    const maxDistanceBtwImageAndClick = 60;
    var _nextEdgeId = -1;
    var aBarnesHutTree = 0;
    var d = null;
    var eBarnesHutTree = null;
    var nParticleSystem = null;
    var oParticleSystem = null;
    var uParticleSystem = null;
    var allClicked = false;
    var c = { particles: {}, springs: {} };
    var l = { particles: {} };
    var bPhysics = { sum: 0, max: 0, mean: 0 };
    var gPhysics = {
      topleft: {
        x: -6,
        y: 6,
      },
      bottomright: {
        x: 6,
        y: -6,
      },
    };
    var cParticleSystem = {
      nodes: {},
      edges: {},
      adjacency: {},
      names: {},
    };
    const initRenderer = () => {
      window.addEventListener("resize", resize);
      resize();
      initMouseHandling();
    };
    const redraw = () => {
      const ctx = canvas.current.getContext("2d");

      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
      ctx.strokeStyle = "#000000";
      ctx.lineWidth = 0.05;

      // Draw lines btw images
      eachEdgeG((pt1, pt2) => {
        ctx.beginPath();

        ctx.moveTo(pt1.x, pt1.y);
        ctx.lineTo(pt2.x, pt2.y);
        ctx.stroke();
        ctx.restore();
      });
      ctx.save();

      // let widthAcc = 0;
      // categories.forEach((e) => {
      //   ctx.font = "15px Arial";
      //   widthAcc += document.body.scrollWidth * e.dimension;
      //   ctx.beginPath();
      //   ctx.moveTo(widthAcc, 0);
      //   ctx.lineTo(widthAcc, document.body.scrollHeight);
      //   ctx.stroke();
      // });

      // Draw Images
      eachNodeG((node, pt) => {
        // if (node.data.seen) ctx.filter = "invert(100%)";
        if (node.data.seen) ctx.filter = "opacity(50%)";

        // Decomment to test selected image after dbclick
        // if (node.data.red) ctx.filter = "drop-shadow(-9px 9px 10px #FF0000)";

        // draw the image
        canvas.current
          .getContext("2d")
          .drawImage(
            node.data.image,
            0,
            0,
            fixedWidth,
            (node.data.height * fixedWidth) / node.data.width,
            pt.x - fixedWidth / 2,
            pt.y - (node.data.height * fixedWidth) / (node.data.width * 2),
            fixedWidth,
            (node.data.height * fixedWidth) / node.data.width
          );
        if (node.data.seen) ctx.filter = "none";
      });
    };
    const resize = () => {
      uParticleSystem = {
        width: document.body.scrollWidth,
        height: document.body.scrollHeight - 4,
      };

      canvas.current.width = uParticleSystem.width;
      canvas.current.height = uParticleSystem.height;
      _updateBoundsG();
    };
    const initMouseHandling = () => {
      var selected = null;
      var dragged = null;

      // To count clicked images
      canvas.current.addEventListener("click", (e) => {
        var rect = canvas.current.getBoundingClientRect();
        var pos = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };
        var p = { x: e.pageX - pos.left, y: e.pageY - pos.top };
        selected = nearestImageToClick(p);
        if (selected == null) {
          return;
        }

        if (selected.node !== null) {
          cParticleSystem.nodes[selected.node.id].data.clicked = true;
        }

        if (
          !Object.values(cParticleSystem.nodes).some(
            (x) => x.data.clicked == false
          )
        ) {
          setTimeout(() => {
            allClicked = true;
          }, 500);
          setTimeout(() => {
            resetClickedImages();
          }, 1000);
        }
      });

      // To view image
      canvas.current.addEventListener("dblclick", (e) => {
        var rect = canvas.current.getBoundingClientRect();
        var pos = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };
        var p = { x: e.pageX - pos.left, y: e.pageY - pos.top };
        selected = nearestImageToClick(p);
        if (selected == null) {
          return;
        }

        if (selected?.distance > 28) {
          return;
        }

        if (selected.node !== null) {
          cParticleSystem.nodes[selected.node.id].data.seen = true;

          var items = JSON.parse(
            localStorage.getItem(`${year}-${season}-seenimages`)
          );
          items.find((x) => x.id == selected.node.id).seen = true;
          localStorage.setItem(
            `${year}-${season}-seenimages`,
            JSON.stringify(items)
          );
          setOpen(true);
          axios
            .get(selected.node.data.url, { responseType: "blob" })
            .then((res) => {
              if (res.status === 200) {
                const url = URL.createObjectURL(res.data);
                setUrlImage(url);
                const img = new Image();
                img.src = url;
                img.onload = () => {
                  URL.revokeObjectURL(url);
                };
              }
            });
        }
      });

      // To choose an image to move
      canvas.current.addEventListener("mousedown", (e) => {
        var rect = canvas.current.getBoundingClientRect();
        var pos = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };
        var p = { x: e.pageX - pos.left, y: e.pageY - pos.top };
        selected = dragged = nearestImageToClick(p);

        if (selected == null) {
          return;
        }

        if (selected.node !== null) {
          dragged.node.fixed = true;
          c.particles[dragged.node.id].fixed = true;
        }
        return false;
      });

      // To move image
      canvas.current.addEventListener("mousemove", (e) => {
        var rect = canvas.current.getBoundingClientRect();
        var pos = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };
        var s = { x: e.pageX - pos.left, y: e.pageY - pos.top };

        if (dragged !== null && dragged.node !== null) {
          var p = fromScreenG(s);
          dragged.node.p = { x: p.x, y: p.y };
          c.particles[dragged.node.id].p.x = p.x;
          c.particles[dragged.node.id].p.y = p.y;
        }

        return false;
      });

      // To let image after moving it
      canvas.current.addEventListener("mouseup", () => {
        if (dragged === null || dragged.node === undefined) return;
        dragged.node.fixed = false;
        c.particles[dragged.node.id].fixed = false;
        if (c.particles[dragged.node.id]._m === undefined) {
          c.particles[dragged.node.id]._m = c.particles[dragged.node.id].m;
        }
        c.particles[dragged.node.id].m = 1000;
        dragged = null;
        selected = null;
        return false;
      });

      // To navigate images
      window.addEventListener("keydown", (e) => {
        if (selected == null) {
          return;
        }

        if (
          !["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)
        ) {
          return;
        }

        let clickedPoint = cParticleSystem.nodes[selected.node.id].p;

        let otherNodes = Object.keys(cParticleSystem.nodes).filter(
          (x) => x != selected.node.id
        );

        let diffX = 0.28;
        let diffY = 0.5;
        let i = 0;

        let w = findNearestImage(otherNodes, clickedPoint, e.key, diffX, diffY);

        while (!w.node && i <= 40) {
          diffX += 0.1;
          diffY += 0.2;
          w = findNearestImage(otherNodes, clickedPoint, e.key, diffX, diffY);
          i++;
        }

        if (w.node == null) {
          return;
        }

        w.distance = magnitude(
          subtract(toScreenG(w.node.p), toScreenG(clickedPoint))
        );
        selected = w;
        // Decomment To test selected image after dbclick
        // Object.keys(cParticleSystem.nodes).forEach((key, i) => {
        //   cParticleSystem.nodes[key].data.red = false;
        // });
        // cParticleSystem.nodes[w.node.id].data.red = true;
        // Comment To test selected image after dbclick
        axios.get(w.node.data.url, { responseType: "blob" }).then((res) => {
          if (res.status === 200) {
            const url = URL.createObjectURL(res.data);
            setUrlImage(url);
            const img = new Image();
            img.src = url;
            img.onload = () => {
              URL.revokeObjectURL(url);
            };
          }
        });
      });

      const findNearestImage = (
        otherNodes,
        clickedPoint,
        key,
        diffX,
        diffY
      ) => {
        let w = { node: null, point: null, distance: null };
        for (let i = 0; i < otherNodes.length; i++) {
          let nextPoint = cParticleSystem.nodes[otherNodes[i]].p;
          switch (key) {
            case "ArrowUp":
              if (clickedPoint.y <= nextPoint.y) {
                continue;
              }
              if (
                !(
                  clickedPoint.x - diffX <= nextPoint.x &&
                  nextPoint.x <= clickedPoint.x + diffX
                )
              ) {
                continue;
              }
              break;
            case "ArrowDown":
              if (clickedPoint.y >= nextPoint.y) {
                continue;
              }
              if (
                !(
                  clickedPoint.x - diffX <= nextPoint.x &&
                  nextPoint.x <= clickedPoint.x + diffX
                )
              ) {
                continue;
              }
              break;
            case "ArrowLeft":
              if (clickedPoint.x <= nextPoint.x) {
                continue;
              }
              if (
                !(
                  clickedPoint.y - diffY <= nextPoint.y &&
                  nextPoint.y <= clickedPoint.y + diffY
                )
              ) {
                continue;
              }
              break;
            case "ArrowRight":
              if (clickedPoint.x >= nextPoint.x) {
                continue;
              }
              if (
                !(
                  clickedPoint.y - diffY <= nextPoint.y &&
                  nextPoint.y <= clickedPoint.y + diffY
                )
              ) {
                continue;
              }
              break;
            default:
              continue;
          }
          var A = magnitude(subtract(nextPoint, clickedPoint));
          if (w.distance === null || A < w.distance) {
            w = {
              node: cParticleSystem.nodes[otherNodes[i]],
              point: nextPoint,
              distance: A,
            };
            w.screenpoint = toScreenG(nextPoint);
          }
        }
        return w;
      };
    };
    const resetClickedImages = () => {
      allClicked = false;
      Object.keys(cParticleSystem.nodes).forEach((key, i) => {
        cParticleSystem.nodes[key].data.clicked = false;
      });
    };
    const insertBarnesHutTree = (particle) => {
      var f = eBarnesHutTree;
      var particles = [particle];
      var index = 0;
      while (particles.length && index < 300) {
        index++;
        var h = particles.shift();
        var m = h._m || h.m;
        var p = _whichQuad(h, f);
        if (f[p] === undefined) {
          f[p] = h;
          f.mass += m;
          if (f.p) {
            f.p = add(f.p, multiply(h.p, m));
          } else {
            f.p = multiply(h.p, m);
          }
        } else {
          if ("origin" in f[p]) {
            f.mass += m;
            f.p = add(f.p, multiply(h.p, m));
            f = f[p];
            particles.unshift(h);
          } else {
            var l = divide(f.size, 2);
            var n = {
              x: f.origin.x,
              y: f.origin.y,
            };
            if (p[0] === "s") {
              n.y += l.y;
            }
            if (p[1] === "e") {
              n.x += l.x;
            }
            var o = f[p];
            f[p] = _newBranch();
            f[p].origin = n;
            f[p].size = l;
            f.mass = m;
            f.p = multiply(h.p, m);
            f = f[p];
            particles.push(o);
            particles.unshift(h);
          }
        }
      }
    };
    const applyForcesBarnesHutTree = (particle) => {
      var f = [eBarnesHutTree];
      while (f.length) {
        var node = f.shift();
        if (node === undefined) {
          continue;
        }
        if (particle === node) {
          continue;
        }
        if ("f" in node) {
          var k = subtract(particle.p, node.p);
          var l = Math.max(1, magnitude(k));
          var i = normalize(k);
          // repulsion 600
          applyForce(
            particle,
            divide(multiply(i, 600 * (node._m || node.m)), l * l)
          );
        } else {
          var j = magnitude(subtract(particle.p, divide(node.p, node.mass)));
          var h = Math.sqrt(node.size.x * node.size.y);
          // theta
          if (h / j > 0.4) {
            f.push(node.ne);
            f.push(node.nw);
            f.push(node.se);
            f.push(node.sw);
          } else {
            var k = subtract(particle.p, divide(node.p, node.mass));
            var l = Math.max(1, magnitude(k));
            var i = normalize(k);
            // repulsion 600
            applyForce(particle, divide(multiply(i, 600 * node.mass), l * l));
          }
        }
      }
    };
    const _whichQuad = (i, f) => {
      var h = subtract(i.p, f.origin);
      var g = divide(f.size, 2);
      if (h.y < g.y) {
        if (h.x < g.x) {
          return "nw";
        } else {
          return "ne";
        }
      } else {
        if (h.x < g.x) {
          return "sw";
        } else {
          return "se";
        }
      }
    };
    const _newBranch = () => {
      if (bBarnesHutTree[aBarnesHutTree]) {
        var f = bBarnesHutTree[aBarnesHutTree];
        f.ne = f.nw = f.se = f.sw = undefined;
        f.mass = 0;
        delete f.p;
      } else {
        f = {
          origin: null,
          size: null,
          nw: undefined,
          ne: undefined,
          sw: undefined,
          se: undefined,
          mass: 0,
        };
        bBarnesHutTree[aBarnesHutTree] = f;
      }
      aBarnesHutTree++;
      return f;
    };
    const addNode = (u) => {
      var t = u.id;
      var q = u.m;

      var category = categories.find((e) => e.shadeId === +data.nodes[t].shade);
      var p = generatePoint(category.x1, category.x2);
      var r = {
        x: p.x,
        y: p.y,
      };

      c.particles[t] = {
        x1: category.x1,
        x2: category.x2,
        p: r,
        m: q,
        v: {
          x: 0,
          y: 0,
        },
        f: {
          x: 0,
          y: 0,
        },
      };
      c.particles[t].connections = 0;
      c.particles[t].fixed = u.f === 1;
      l.particles[t] = c.particles[t];
    };
    const addSpring = (t) => {
      var s = t.id;
      var p = t.l;
      var r = c.particles[t.fm];
      var q = c.particles[t.to];
      if (r !== undefined && q !== undefined) {
        c.springs[s] = {
          point1: r,
          point2: q,
          length: p,
          // stiffness
          k: 1000,
        };
        r.connections++;
        q.connections++;
        delete l.particles[t.fm];
        delete l.particles[t.to];
      }
    };
    const tock = () => {
      var p = [];
      Object.keys(c.particles).forEach((key) => {
        p.push(key);
        p.push(c.particles[key].p.x);
        p.push(c.particles[key].p.y);
      });
      if (_updateGeometryG) {
        _updateGeometryG({
          geometry: p,
          energy: bPhysics,
          bounds: gPhysics,
        });
      }
    };
    const tendParticles = () => {
      Object.keys(c.particles).forEach((key) => {
        c.particles[key].v.x = c.particles[key].v.y = 0;
      });
    };
    const eulerIntegrator = () => {
      applyBarnesHutRepulsion();
      if (!allClicked) {
        applySprings();
      }
      updateVelocity();
      updatePosition();
      if (allClicked) {
        adaptPosition();
      }
    };
    const applyBarnesHutRepulsion = () => {
      var q = {
        x: gPhysics.bottomright.x,
        y: gPhysics.bottomright.y,
      };
      var p = {
        x: gPhysics.topleft.x,
        y: gPhysics.topleft.y,
      };
      aBarnesHutTree = 0;
      eBarnesHutTree = _newBranch();
      eBarnesHutTree.origin = p;
      eBarnesHutTree.size = subtract(q, p);
      Object.keys(c.particles).forEach((key) => {
        insertBarnesHutTree(c.particles[key]);
      });
      Object.keys(c.particles).forEach((key) => {
        applyForcesBarnesHutTree(c.particles[key]);
      });
    };
    const applySprings = () => {
      Object.keys(c.springs).forEach((key) => {
        var s = subtract(c.springs[key].point2.p, c.springs[key].point1.p);
        var q = c.springs[key].length - magnitude(s);
        var r = normalize(s);
        applyForce(
          c.springs[key].point1,
          multiply(r, c.springs[key].k * q * -0.5)
        );
        applyForce(
          c.springs[key].point2,
          multiply(r, c.springs[key].k * q * 0.5)
        );
      });
    };
    const updateVelocity = () => {
      Object.keys(c.particles).forEach((key) => {
        if (c.particles[key].fixed) {
          c.particles[key].v = {
            x: 0,
            y: 0,
          };
          c.particles[key].f = {
            x: 0,
            y: 0,
          };
          return;
        }
        magnitude(c.particles[key].v);
        c.particles[key].v = multiply(
          add(c.particles[key].v, multiply(c.particles[key].f, 0.02)),
          1 - 0.3 // friction
        );
        c.particles[key].f.x = c.particles[key].f.y = 0;
        var r = magnitude(c.particles[key].v);
        if (r > 1000) {
          c.particles[key].v = divide(c.particles[key].v, r * r);
        }
      });
    };
    const updatePosition = () => {
      var r = 0,
        p = 0,
        u = 0;
      var t = null;
      var s = null;
      Object.keys(c.particles).forEach((key) => {
        c.particles[key].p = add(
          c.particles[key].p,
          multiply(c.particles[key].v, 0.02)
        );
        var x = magnitude(c.particles[key].v);
        var z = x * x;
        r += z;
        p = Math.max(z, p);
        u++;
        if (!t) {
          t = {
            x: c.particles[key].p.x,
            y: c.particles[key].p.y,
          };
          s = {
            x: c.particles[key].p.x,
            y: c.particles[key].p.y,
          };
          return;
        }
        var y = c.particles[key].p;
        if (y.x === null || y.y === null) {
          return;
        }
        if (y.x > t.x) {
          t.x = y.x;
        }
        if (y.y > t.y) {
          t.y = y.y;
        }
        if (y.x < s.x) {
          s.x = y.x;
        }
        if (y.y < s.y) {
          s.y = y.y;
        }
      });
      bPhysics = { sum: r, max: p, mean: r / u, n: u };
      gPhysics = {
        topleft: s || {
          x: -6,
          y: -6,
        },
        bottomright: t || {
          x: 6,
          y: 6,
        },
      };
    };
    const adaptPosition = () => {
      Object.keys(c.particles).forEach((key) => {
        c.particles[key].p.x = adaptPoint(
          c.particles[key].p.x,
          c.particles[key].x2,
          c.particles[key].x1
        );
      });
    };
    const physicsUpdateJ = () => {
      tendParticles();
      eulerIntegrator();
      tock();
      _updateBoundsG();
      redraw();
    };
    const startJ = () => {
      if (d !== null) {
        return;
      }

      initRenderer();
      d = setInterval(physicsUpdateJ, 50);
    };
    function applyForce(p, a) {
      p.f = add(p.f, divide(a, p.m));
    }
    function add(p, a) {
      return {
        x: p.x + a.x,
        y: p.y + a.y,
      };
    }
    function subtract(p, a) {
      return {
        x: p.x - a.x,
        y: p.y - a.y,
      };
    }
    function multiply(p, a) {
      return {
        x: p.x * a,
        y: p.y * a,
      };
    }
    function divide(p, a) {
      return {
        x: p.x / a,
        y: p.y / a,
      };
    }
    function magnitude(p) {
      return Math.sqrt(p.x * p.x + p.y * p.y);
    }
    function normalize(p) {
      return divide(p, magnitude(p));
    }
    const addNodeG = (w, B) => {
      var z = {
        id: B.id,
        data: B,
        mass: 1,
        fixed: false,
        p: {
          x: null,
          y: null,
        },
      };
      cParticleSystem.names[w] = z;
      cParticleSystem.nodes[z.id] = z;
      addNode({
        id: z.id,
        m: z.mass,
        x: null,
        y: null,
        f: 0,
      });
      return z;
    };
    const getNodeG = (v) => {
      return cParticleSystem.names[v];
    };
    const eachNodeG = (v) => {
      Object.keys(cParticleSystem.nodes).forEach((key) => {
        if (
          cParticleSystem.nodes[key].p.x == null ||
          cParticleSystem.nodes[key].p.y == null
        ) {
          return;
        }
        var w = toScreenG(cParticleSystem.nodes[key].p);
        v.call(null, cParticleSystem.nodes[key], w);
      });
    };
    const addEdgeG = (z, A, y) => {
      z = getNodeG(z);
      A = getNodeG(A);
      var x = {
        id: _nextEdgeId--,
        source: z,
        target: A,
        length: y.length,
        data: y,
      };
      var B = z.id;
      var C = A.id;
      cParticleSystem.adjacency[B] = cParticleSystem.adjacency[B] || {};
      cParticleSystem.adjacency[B][C] = cParticleSystem.adjacency[B][C] || [];
      cParticleSystem.edges[x.id] = x;
      cParticleSystem.adjacency[B][C].push(x);
      addSpring({
        id: x.id,
        fm: B,
        to: C,
        l: x.length,
      });
      return x;
    };
    const eachEdgeG = (v) => {
      Object.keys(cParticleSystem.edges).forEach((key) => {
        var y = cParticleSystem.nodes[cParticleSystem.edges[key].source.id].p;
        var w = cParticleSystem.nodes[cParticleSystem.edges[key].target.id].p;
        if (y.x == null || w.x == null) {
          return;
        }
        y = toScreenG(y);
        w = toScreenG(w);
        if (y && w) {
          v.call(null, y, w);
        }
      });
    };
    const graftG = (w) => {
      Object.keys(w.nodes).forEach((key) => {
        addNodeG(key, w.nodes[key]);
      });
      Object.keys(w.edges).forEach((key) => {
        Object.keys(w.edges[key]).forEach((key1) => {
          addEdgeG(key, key1, w.edges[key][key1]);
        });
      });
      startJ();
    };
    const _updateGeometryG = (y) => {
      var z = y.geometry;
      if (z !== undefined) {
        for (var x = 0, w = z.length / 3; x < w; x++) {
          var A = z[3 * x];
          if (cParticleSystem.nodes[A] === undefined) {
            continue;
          }
          cParticleSystem.nodes[A].p.x = z[3 * x + 1];
          cParticleSystem.nodes[A].p.y = z[3 * x + 2];
        }
      }
    };
    const toScreenG = (x) => {
      if (!nParticleSystem || !uParticleSystem) {
        return;
      }
      var v = subtract(nParticleSystem.bottomright, nParticleSystem.topleft);
      var z =
        iParticleSystem[3] +
        divide(subtract(x, nParticleSystem.topleft), v.x).x *
          (uParticleSystem.width - (iParticleSystem[1] + iParticleSystem[3]));
      var y =
        iParticleSystem[0] +
        divide(subtract(x, nParticleSystem.topleft), v.y).y *
          (uParticleSystem.height - (iParticleSystem[0] + iParticleSystem[2]));

      // Keep images in screen
      if (z > uParticleSystem.width - fixedWidth / 2)
        z = uParticleSystem.width - fixedWidth / 2;
      if (z < fixedWidth / 2) z = fixedWidth / 2;

      if (y > uParticleSystem.height - fixedHeight / 2)
        y = uParticleSystem.height - fixedHeight / 2;
      if (y < fixedHeight / 2) y = fixedHeight / 2;
      return {
        x: z,
        y: y,
      };
    };
    const fromScreenG = (z) => {
      if (!nParticleSystem || !uParticleSystem) {
        return;
      }
      var x = subtract(nParticleSystem.bottomright, nParticleSystem.topleft);
      var w =
        ((z.x - iParticleSystem[3]) /
          (uParticleSystem.width - (iParticleSystem[1] + iParticleSystem[3]))) *
          x.x +
        nParticleSystem.topleft.x;
      var v =
        ((z.y - iParticleSystem[0]) /
          (uParticleSystem.height -
            (iParticleSystem[0] + iParticleSystem[2]))) *
          x.y +
        nParticleSystem.topleft.y;
      return {
        x: w,
        y: v,
      };
    };
    const _updateBoundsG = () => {
      oParticleSystem = boundsG();
      var z = {
        x: oParticleSystem.bottomright.x,
        y: oParticleSystem.bottomright.y,
      };
      var y = {
        x: oParticleSystem.topleft.x,
        y: oParticleSystem.topleft.y,
      };
      var B = subtract(z, y);
      var v = add(y, divide(B, 2));
      var x = 4;
      var D = {
        x: Math.max(B.x, x),
        y: Math.max(B.y, x),
      };
      oParticleSystem.topleft = subtract(v, divide(D, 2));
      oParticleSystem.bottomright = add(v, divide(D, 2));
      if (!nParticleSystem) {
        if (isEmpty(cParticleSystem.nodes)) {
          return false;
        }
        nParticleSystem = oParticleSystem;
        return true;
      }
      var _newBounds = {
        bottomright: add(
          nParticleSystem.bottomright,
          multiply(
            subtract(oParticleSystem.bottomright, nParticleSystem.bottomright),
            0.02
          )
        ),
        topleft: add(
          nParticleSystem.topleft,
          multiply(
            subtract(oParticleSystem.topleft, nParticleSystem.topleft),
            0.02
          )
        ),
      };
      var A = {
        x: magnitude(subtract(nParticleSystem.topleft, _newBounds.topleft)),
        y: magnitude(
          subtract(nParticleSystem.bottomright, _newBounds.bottomright)
        ),
      };
      if (A.x * uParticleSystem.width > 1 || A.y * uParticleSystem.height > 1) {
        nParticleSystem = _newBounds;
        return true;
      } else {
        return false;
      }
    };
    const boundsG = () => {
      var w = null;
      var v = null;
      Object.keys(cParticleSystem.nodes).forEach((key) => {
        if (!w) {
          w = {
            x: cParticleSystem.nodes[key].p.x,
            y: cParticleSystem.nodes[key].p.y,
          };
          v = {
            x: cParticleSystem.nodes[key].p.x,
            y: cParticleSystem.nodes[key].p.y,
          };
          return;
        }
        var x = cParticleSystem.nodes[key].p;
        if (x.x === null || x.y === null) {
          return;
        }
        if (x.x > w.x) {
          w.x = x.x;
        }
        if (x.y > w.y) {
          w.y = x.y;
        }
        if (x.x < v.x) {
          v.x = x.x;
        }
        if (x.y < v.y) {
          v.y = x.y;
        }
      });
      if (w && v) {
        return { bottomright: w, topleft: v };
      } else {
        return {
          topleft: {
            x: -6,
            y: 6,
          },
          bottomright: {
            x: 6,
            y: -6,
          },
        };
      }
    };
    const nearestImageToClick = (x) => {
      if (uParticleSystem !== null) {
        x = fromScreenG(x);
      }
      var w = { node: null, point: null, distance: null };
      Object.keys(cParticleSystem.nodes).forEach((key) => {
        var z = cParticleSystem.nodes[key].p;
        if (z.x === null || z.y === null) {
          return;
        }
        var A = magnitude(subtract(z, x));
        if (w.distance === null || A < w.distance) {
          w = { node: cParticleSystem.nodes[key], point: z, distance: A };
          if (uParticleSystem !== null) {
            w.screenPoint = toScreenG(z);
          }
        }
      });
      if (w.node) {
        if (uParticleSystem !== null) {
          w.distance = magnitude(subtract(toScreenG(w.node.p), toScreenG(x)));
          if (w.distance >= maxDistanceBtwImageAndClick) {
            return null;
          }
        }
        return w;
      } else {
        return null;
      }
    };
    graftG(data);
  };

  const getEdgeLength = (shade1, shade2) => {
    if (shade1 == shade2) return 2;
    else return 2;
  };

  const getImages = async (imgs) => {
    let dataTmp = {
      nodes: {},
      edges: {},
    };

    var storedSeenImages = localStorage.getItem(`${year}-${season}-seenimages`);

    if (storedSeenImages == null) {
      storedSeenImages = JSON.stringify(
        imgs.map((x) => {
          return {
            id: x.id,
            seen: false,
          };
        })
      );
      localStorage.setItem(`${year}-${season}-seenimages`, storedSeenImages);
    }

    var seenImages = JSON.parse(storedSeenImages);

    for (let i = 0; i < imgs.length; i++) {
      dataTmp.nodes[imgs[i].id] = {
        id: imgs[i].id,
        width: imgs[i].width,
        height: imgs[i].height,
        shade: imgs[i].shades[0].id,
        seen: seenImages.find((x) => x.id == imgs[i].id)?.seen || false,
        clicked: false,
        url: imgs[i].url,
      };
    }

    await Promise.all(
      imgs.map(async (x) => {
        let res = await axios.get(x.urlResized, { responseType: "blob" });
        if (res.status === 200) {
          const url = URL.createObjectURL(res.data);
          var image = new Image();
          image.src = url;
          dataTmp.nodes[x.id].image = image;
          image.onload = () => {
            URL.revokeObjectURL(url);
          };
        }
      })
    );

    for (let i = 0; i < imgs.length; i++) {
      let edge = {};
      let otherImgs = imgs.filter((x) => x.id !== imgs[i].id);

      for (let j = 0; j < otherImgs.length; j++) {
        edge[otherImgs[j].id] = {
          length: getEdgeLength(
            imgs[i].shades[0].id,
            otherImgs[j].shades[0].id
          ),
        };
      }

      dataTmp.edges[imgs[i].id] = edge;
    }

    var groupedShade = groupBy(imgs, "shades[0].id");

    var d = 0;
    var x1 = xMin;
    var x2 = 0;

    var categoriesTmp = Object.keys(groupedShade).map((e) => {
      let dimension = +(groupedShade[e].length / imgs.length).toFixed(3);
      d += dimension;
      x2 = +(xMin + (Math.abs(xMin) + Math.abs(xMax)) * d).toFixed(2);
      let obj = {
        shadeId: +e,
        dimension,
        x1: x1,
        x2: x2,
      };
      x1 = x2;
      return obj;
    });

    setCategories(categoriesTmp);
    setData(dataTmp);
  };

  const random = (max, min) => {
    if (max >= 0 && min <= 0) return Math.random() * (max - min) + min;
    else if (max < 0 && min < 0) return Math.random() * (-min + max) + min;
    else if (max > 0 && min > 0) return Math.random() * (max - min) + min;
  };

  const adaptPoint = (x, max, min) => {
    if (x > max) return x - 0.01;
    else if (x < min) return x + 0.01;
    else return x;
  };

  const generatePoint = (x1, x2) => {
    let x = +random(x2, x1).toFixed(2);
    let y = +random(yMax, yMin).toFixed(2);

    return {
      x,
      y,
    };
  };

  const clear = () => {
    canvas.current
      .getContext("2d")
      .clearRect(
        0,
        0,
        canvas.current?.getContext("2d").canvas.width,
        canvas.current?.getContext("2d").canvas.height
      );
  };

  const ModalMemorized = useMemo(
    () => (
      <div>
        <Modal
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          className="modal"
          ariaHideApp={false}
        >
          <img
            className="modal-img"
            src={urlImage}
            alt=""
            onContextMenu={(e) => e.preventDefault()}
          />
        </Modal>
      </div>
    ),
    [open, urlImage]
  );

  useEffect(() => {
    if (
      range(2005, +moment().format("YYYY")).includes(+year) &&
      ["spring", "summer", "fall"].includes(season)
    ) {
      axios
        .get(
          "/api/images?year=" + year + "&season=" + SEASON[season.toUpperCase()]
        )
        .then((res) => getImages(res.data));
      lair.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
    return () => clear();
  }, []);

  useEffect(() => {
    if (data.nodes != null) {
      Maps();
    }
  }, [data]);

  return (
    <div className="rel screen-200">
      <canvas ref={canvas} onContextMenu={(e) => e.preventDefault()}></canvas>
      {ModalMemorized}
      <div className="lair" ref={lair}></div>
    </div>
  );
}

export default Delta;
